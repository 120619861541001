import Header from './components/header';
import Sections from './components/section';
import Sidebar from './components/sidebar';

new Header();
new Sections();
new Sidebar();



// function keyClick(letter, el) {
//     document.addEventListener('keypress', e => {
//         if(e.key === letter) el.click();
//     });
// }

// keyClick('p', document.querySelector('.jakiś_przycisk'));


// function scrollTopPage(el){
//     el.addEventListener('click', e => {
//         window.scroll({
//             top: 0,
//             left: 0,
//             behavior: 'smooth'
//         });
//     })
// }

// scrollTopPage(document.querySelector('.jakis_przycisk'));