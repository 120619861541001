import Swiper from 'swiper/js/swiper.js';
import Choices from 'choices.js/public/assets/scripts/choices.js';
import { CountUp } from 'countup.js';

import Core from './core';
class Sections{
    constructor() {
        this.core = new Core();
        this.banner_slider = document.querySelector('.banner-slider') ? document.querySelectorAll('.banner-slider') : null;
        this.accordions = document.querySelector('.accordions') ? document.querySelectorAll('.accordions') : null;
        this.blogs_news = document.querySelector('.blogs-news') ? document.querySelectorAll('.blogs-news') : null;
        this.video = document.querySelector('.video') ? document.querySelectorAll('.video') : null;
        this.image_gallery_slider = document.querySelector('.image-gallery-slider') ? document.querySelectorAll('.image-gallery-slider') : null;
        this.page_sub_nav = document.querySelector('.page-sub-nav') ? document.querySelectorAll('.page-sub-nav') : null;
        this.tab_content = document.querySelector('.tab_content') ? document.querySelectorAll('.tab_content') : null;
        this.testimonial_slider = document.querySelector('.testimonial-slider') ? document.querySelectorAll('.testimonial-slider') : null;
        this.startCounters = document.querySelector('.start-counters') ? document.querySelectorAll('.start-counters') : null;
        this.slider_lists = document.querySelector('.slider-lists') ? document.querySelectorAll('.slider-lists') : null;
        this.switch_list_speakers = document.querySelector('.switch_list') ? document.querySelectorAll('.switch_list') : null;
        this.join_us = document.querySelector('.join-us') ? document.querySelectorAll('.join-us') : null;
        this.left_nav = document.querySelector('.hand-nav') ? document.querySelectorAll('.hand-nav') : null;


        !this.left_nav || this.left_nav.forEach(el => this.sNav(el));
        !this.join_us || this.join_us.forEach(el => this.sPop(el));
        !this.banner_slider || this.banner_slider.forEach(el => this.sBannerSlider(el));
        !this.accordions || this.accordions.forEach(el => this.sAccordions(el));
        !this.blogs_news || this.blogs_news.forEach(el => this.sBlogs(el));
        !this.video || this.video.forEach(el => this.videoPlayer(el));
        !this.image_gallery_slider || this.image_gallery_slider.forEach(el => this.sTestimonial(el));
        !this.tab_content || this.tab_content.forEach(el => this.sTab(el));
        !this.testimonial_slider || this.testimonial_slider.forEach(el => this.sTestimonial(el));
        !this.startCounters || this.startCounters.forEach(el => this.counters(el));
        !this.slider_lists || this.slider_lists.forEach(el => this.sSliderList(el));

        if (this.page_sub_nav && document.body.clientWidth <= 991) this.page_sub_nav.forEach(el => this.toggleSub(el));

        document.querySelector('.select') ? document.querySelectorAll('.select').forEach(el => new Choices(el, {
            searchEnabled: false,
            itemSelectText: '',
            shouldSort: false
        })) : null;

        !this.switch_list_speakers || this.switch_list_speakers.forEach(el => {
            if (document.body.clientWidth <= 776) this.switchList(el.querySelector('.switch_list__grid--mobile'));
            else this.switchList(el.querySelector('.switch_list__grid:not(.switch_list__grid--mobile)'));
        });


        

    }

    sNav(el){
        el.querySelector('nav h4').addEventListener('click', e => {
            el.querySelector('nav h4').classList.toggle('active');
            this.core.slideToggle(el.querySelector('nav ul'), 500);
        })
    }

    sPop(el){
        el.querySelector('.view').addEventListener('click', e => {
            e.preventDefault();
            el.querySelector('.pop_pricing').classList.add('active');
        
        })
        el.querySelector('.pop_pricing .close').addEventListener('click', e => {
            e.preventDefault();
            el.querySelector('.pop_pricing').classList.remove('active');
        })
    }
    switchList(el) {
        el.querySelectorAll('.box').forEach(item => {
            item.querySelectorAll('.circle span').forEach(span => span.addEventListener('click', e => {
                if (item.classList.contains('active')) {
                    item.classList.remove('active');
                    this.core.slideUp(el.querySelector(`.info_box[data-item='${item.dataset.item}']`), 300);
                } else {
                    el.querySelectorAll('.box').forEach(it => it.classList.remove('active'));
                    el.querySelectorAll('.info_box').forEach(it => this.core.slideUp(it, 100));

                    item.classList.add('active');
                    setTimeout(() => {
                        this.core.slideDown(el.querySelector(`.info_box[data-item='${item.dataset.item}']`), 300);

                    }, 100);

                }
            }));
        })
    }

    sSliderList(el){
        new Swiper(el.querySelector(".mySwiper"), {
            slidesPerView:1.3,
            spaceBetween: 15,
            centeredSlides: true,
            loop:true,
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
            breakpoints: {
                600: {
                    slidesPerView: 2.2,
                    spaceBetween: 15,
                },
                1056: {
                    slidesPerView: 3,
                    spaceBetween: 15,
                },
            },
        });
    }

    sBannerSlider(el){
        new Swiper(el.querySelector(".mySwiper"), {
            effect: "fade",
            autoHeight: true,
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },
            autoplay: {
                delay: 3500,
                disableOnInteraction: false,
            },
        });
    }

    counters(el) {
        el.querySelectorAll('li').forEach(item => {
            let cl = item.querySelector('h3');
            console.log(typeof Number(cl.dataset.number));
            let countUp = new CountUp(cl, Number(cl.dataset.number));
            if (!countUp.error) {
                countUp.start();
            } else {
                console.error(countUp.error);
            }
        })
    }


    sTab(el) {
        el.querySelectorAll('.tab_content__lists .item:not(.item--inactive)').forEach(item => {
            item.addEventListener('click', e => {
                el.querySelectorAll('.tab_content__lists .item').forEach(i => i.classList.remove('active'));
                e.target.classList.add('active');
                el.querySelectorAll('.tab_content__items .item').forEach(t => t.classList.remove('active'));
                el.querySelector(`.tab_content__items .item[data-index="${e.target.dataset.index}"]`).classList.add('active');
            })
        });
    }
    toggleSub(item){
        item.querySelector('.hamburger').addEventListener('click', e => {
            this.toggleMenu(e, item.querySelector('.container > ul'));
            item.querySelector('.hamburger').classList.toggle('active');
        });
        // item.querySelectorAll('.item.item--sub').forEach(el => el.firstElementChild.addEventListener('click', e => this.toggleMenu(e, el.querySelector('div'))));
        // item.querySelectorAll('.item.item--sub').forEach(el => {
        //     el.querySelectorAll('.item--sub').forEach(i => i.firstElementChild.addEventListener('click', e => this.toggleMenu(e, i.querySelector('ul'))));
        // })
    }
    sTestimonial(el) {
        new Swiper(el.querySelector(".mySwiper"), {
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
        });
    }

    videoPlayer(el) {
        el.querySelector('span').addEventListener('click', e => {
            let video = el.querySelector('video');
            video.play();
            video.controls = true;
            el.classList.add('hidden_play');

        });
    }
    
    sBlogs(el){
        new Swiper(el.querySelector(".mySwiper"), {
            slidesPerView: 1,
            spaceBetween: 20,
            loop: true,
            navigation: {
                nextEl: el.querySelector(".swiper-button-next"),
                prevEl: el.querySelector(".swiper-button-prev"),
            },
            breakpoints: {
                1024: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
            },
            
        });
    }

    sAccordions(el) {
        el.querySelectorAll('.item').forEach(item => {
            item.querySelector('.item__head').addEventListener('click', e => {
                this.core.slideToggle(item.querySelector('.item__content'),300);
                setInterval(() => {
                    if (item.querySelector('.item__content').style.display === 'block'){
                        item.querySelector('.item__head').classList.add('active');
                    }else{
                        item.querySelector('.item__head').classList.remove('active');
                    }
                }, 300);
            })
        })
    }
    toggleMenu(e, itemToggle) {
        e.preventDefault();
        e.target.classList.toggle('active');
        this.core.slideToggle(itemToggle, 500);

    }

}

export default Sections;