import Core from "./core";

class Header{
    constructor(){
        this.core = new Core();
        this.header = document.querySelector('.header');
        if(this.header){
            this.pop_header = document.querySelector('.pop_header');
            this.hamburger = this.header.querySelector(".hamburger");
            this.search_btn = this.header.querySelector('.icon-search');
            this.search_close = this.header.querySelector('.header__search .close');
            this.mobile_pop = document.querySelector('.mobile_pop');
            !this.mobile_pop || this.mobilePop(this.mobile_pop);
            !this.pop_header || this.pop_header.querySelector('.close').addEventListener('click', e => this.pop_header.classList.remove('active'));
            !this.search_close || this.search_close.addEventListener('click', e => this.searchToggle(e));
            !this.search_btn || this.search_btn.querySelector('a').addEventListener('click', e => {this.searchToggle(e); console.log('test');});
            !this.hamburger || this.hamburger.addEventListener('click', e => this.toggleNav(e));
        }
        
    }

    mobilePop(el){
        el.querySelector('.close').addEventListener('click', e => {
            e.preventDefault();
            el.classList.remove('active');
            this.hamburger.classList.remove('is-active');
        })
        el.querySelectorAll('.nav a.sub').forEach(item => {
            item.addEventListener('click', e => {
                e.preventDefault();
                el.querySelector(`.sub_nav[data-id="${item.dataset.id}"]`).classList.add('active');
                el.querySelector('.nav').classList.add('hidden');
            }) 
        });
        el.querySelectorAll('.back').forEach(item => {
            item.addEventListener('click', e => {
                e.preventDefault();
                el.querySelector('.sub_nav.active').classList.remove('active');
                el.querySelector('.nav').classList.remove('hidden');
            })
        })
    }
    toggleNav(e){
        e.preventDefault();
        e.currentTarget.classList.toggle('is-active');
        document.querySelector('.pop_header').classList.add('active');
        document.querySelector('.mobile_pop').classList.add('active');
    }

    searchToggle(e){
        e.preventDefault();
        let search = this.header.querySelector('.header__search');
        document.body.classList.toggle("active");
        search.classList.toggle('active');
        this.core.slideToggle(search, 500);
    }
    toggleMenu(e, itemToggle){
        e.preventDefault();
        e.target.classList.toggle('active');
        this.core.slideToggle(itemToggle, 500);
        

    }
}

export default Header;