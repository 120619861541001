import '../../../node_modules/sticky-kit/dist/sticky-kit';

class Sidebar{
    constructor() {
        jQuery('.sid >div').stick_in_parent({
            offset_top: 20,
        });
        
    } 
}
export default Sidebar;
